import React from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';
import '@openfonts/m-plus-rounded-1c_japanese';

import { useTrackedState } from '../lib/state';

import SubLayout from '../components/SubLayout';
import { P } from '../components/common/P.style';
import { A } from '../components/common/Link.style';
import { Img } from '../components/common/Img.style';
import { H3, H4 } from '../components/common/H.style';
import Link from '../components/Link';
import Card from '../components/Card';
import PcrMenu from '../components/PcrMenu';
import ListItem from '../components/ListItem';
import ListItemBig from '../components/ListItemBig';
import { ContentWrapper } from '../components/common/ContentWrapper.style';
import ButtonList from '../components/ButtonList';
import Anchor from '../components/Anchor';

import cn_001 from '../assets/img/cn-001.jpg';

import pcr from '../assets/img/pcr.jpg';
import card from '../assets/img/card.png';


const H4pWrapper = styled(Flex)`
  margin: 2em 0;
  border-radius: 25px;
  background-color: #5A91DB;
  box-sizing: border-box;
`;

const StyledH4p = styled.h4`
  margin: .25em 1.5em;
  font-size: ${({ fontSize }) => fontSize}px;
  color: #ffffff;
  font-weight: normal;
  font-family:"M PLUS Rounded 1c",TsukuARdGothic-Regular;
`;

export const H4p = ({ children, size }) => (
  <H4pWrapper alignCenter>
    <StyledH4p fontSize={(size === 'sm' && 12) || 18}>{children}</StyledH4p>
  </H4pWrapper>
);

const H4iWrapper = styled(Flex)`
  margin: 2em 0;
  border-radius: 25px;
  background-color: #DB5A5A;
  box-sizing: border-box;
`;

export const H4i = ({ children, size }) => (
  <H4iWrapper alignCenter>
    <StyledH4p fontSize={(size === 'sm' && 12) || 18}>{children}</StyledH4p>
  </H4iWrapper>
);

const H4cWrapper = styled(Flex)`
margin: 2em 0;
border-radius: 25px;
background-color: #F0862E;
box-sizing: border-box;
text-align: center;
`;

export const H4c = ({ children, size }) => (
  <H4cWrapper alignCenter>
    <StyledH4p fontSize={(size === 'sm' && 12) || 18}>{children}</StyledH4p>
  </H4cWrapper>
);

const PcrButton = styled(Flex)`
  height: 40px;
  max-width: 450px;
  margin: 1em auto;
  padding: .5em 2em;
  border-radius: 6px;
  border: solid 4px #5A91DB;
  background-color: #5A91DB;
  box-sizing: border-box;
`;


const WhiteText = styled.div`
  font-size: 18px;
  color: #ffffff;
`;

const Span = styled.span`
font-size: 0.8em;
`;

const Exams = () => {
  const { size } = useTrackedState();
  return (
    <SubLayout topTitle="驻日中国大使馆指定赴华核酸(PCR)检查">
      <Img src={pcr} />
      <P>
        <H3>我院特点</H3>
        ・交通便利：距离涩谷车站徒步3分钟<br />
        ・服务快捷：最快检查当天可发行中国大使馆指定阴性证明※1<br />
        ・赴华时讯：2023年1月17日起，日本赴华登机前48小时内完成1次核酸检测，结果阴性者即可赴华。<br />
        ※取得核酸检测阴性结果后，通过微信小程序“海关旅客指尖服务”进行申报，无需再申请健康码。※<br />
        有既往感染史、密接、疑似症状等情况人员，不再设置额外要求，申请要求与普通人员一致<br />
        登机时需要向航空公司提供纸质版核酸阴性检测报告（原版，打印版均可）。<br />
        <br />
        详情请参考页底中国驻日本大使馆通告 <br />
        <br />
        预约核酸检查请点击下面预约链接或微信·电话咨询<br />
        微信号：HC-segu<br />
        咨询电话：080-2161-1357（中文）<br />

          <br />
          <a href="https://reserva.be/hirahatacovidtest/reserve?mode=service_staff&search_evt_no=2ceJwzMTU0tzABAARUAT4"
                css={`border:3px solid #f7b13c; border-radius: 6px; background-color: #fffff0; padding: .5em; margin: .5em;`}>
                预约赴华核酸检测
          </a>
          <br />
        <H3>我院赴华核酸（PCR）检测详细信息</H3>
        〇检测方法：鼻咽拭子・唾液（两者均符合现行赴华登机前核酸检查标准） <br />
        〇阴性证明格式：中国大使馆指定格式（仅限鼻咽拭子） ，普通赴华核酸检测格式（唾液）<br />
        〇检查以及阴性证明书发行时间 <br />
        ・09:00～11:15　来院检查→　 <br />
        当天16:00 邮件发送阴性证明PDF版・当晚20点之前可取原件 <br />
        ・11:30～16:15　来院检查→　 <br />
        当晚21:00 邮件发送阴性证明PDF版（需要原件的客人可在次日（周日·法定假日除外）早9:30以后来我院前台领取） <br />
        ・16:30～19:00　来院检查→　 <br />
        次日9:30左右邮件发送阴性证明PDF版・需要原件的客人可在当晚20点之前（周日·法定假日除外）来院取原件 <br />
        ※1：如果有复检等情况，证明书的发行时间会有延长，敬请理解。 <br />
        〇费用： <br />
        ・赴华登机前48小时以内核酸检测：15,000日元（含阴性证明·税·发行PDF电子版） <br />
        <br />
          〇核酸检测为预约制，请在检测前进⾏
          <a href="https://www.hirahata-clinic.or.jp/contact-reservation-pcr/" css={"color:blue;"}>预约</a><br />
          〇检测当天请携带护照原件<br />
          〇检测费用可用现金，信用卡支付（系统更新中，目前不支持支付宝，微信，银联卡支付）
          <br />
          <br />

          <a href="https://reserva.be/hirahatacovidtest/reserve?mode=service_staff&search_evt_no=2ceJwzMTU0tzABAARUAT4"
                css={`border:3px solid #f7b13c; border-radius: 6px; background-color: #fffff0; padding: .5em; margin: .5em;`}>
                预约赴华核酸检测
          </a>
          <br />
          <br />
          <H4>以下为驻日中国大使馆最新回国信息的部分摘要</H4>
          详情参考：驻日中国大使馆 【关于前往中国旅客疫情防控要求的通知　（2023-01-15 20:35更新）】<br />
          根据中国政府2022年12月27日发布的新型冠状病毒感染乙类乙管后中外人员往来暂行措施要求，前往中国旅客须在行前48小时进行核酸检测，结果阴性者可来华。<br />
          为确保国际旅行健康安全，自2023年1月17日起（当地时间），执飞赴华航班的航空公司将负责查验旅客登机前48小时内核酸检测阴性证明，请予以配合。中国海关将在旅客入境时对证明进行抽查。<br />
          为便于前往中国的旅客做好行前各项准备，驻日本使馆整理了《前往中国旅客疫情防控指南》及核酸检测证明要求（附后），请认真阅读，遵照执行，以免影响行程。<br />
          前往中国旅客疫情防控指南<br />
          一、远端检测：登机前48 小时内进行核酸检测，结果阴性者方可来华，结果阳性者请在转阴后来华。请妥为保管检测阴性证明，随身携带，以便查验。<br />
          二、海关申报：取得核酸检测阴性结果后，通过微信小程序“海关旅客指尖服务”、掌上海关APP或网页版(https://htdecl.chinaport.gov.cn）填写《中华人民共和国出/入境健康申明卡》进行申报。 <br />
          <br />
          <Img src={cn_001} />
          <br />
          三、登机查验：航司将在旅客登机时查验48 小时内核酸检测阴性证明，如无法出示则不允许登机。<br />
          四、机上防疫：请在乘机过程中坚持佩戴口罩，做好个人防护，降低染疫风险。<br />
          五、入境检疫：抵达口岸后凭海关健康申报码完成必要通关手续。海关将抽查登机前 48 小时核酸检测阴性证明。健康申报正常且口岸常规检疫无异常者，可进入社会面。健康申报异常或出现发热等症状人员，由海关进行检测。检测结果为阳性者，按照告知书要求进行居家、居所隔离或就医。结果为阴性者，由海关依惯例按照 《国境卫生检疫法》等法律法规实施常规检疫。<br />
          六、属地防疫：旅客入境后应严格遵守属地疫情防控各项要求。<br />
          <br />
          核酸检测证明要求<br />
          一、载有受检人姓名（应与搭乘来华航班使用的旅行证件姓名一致），最好注明出生日期和旅行证件号码。<br />
          二、载有以下检测信息：检测时间或报告出具时间（至少有一个时间在登机前48小时内）、检测方法（应为核酸检测，不接受抗原检测）、检测结果（应为阴性，不接受“不确定”“灰区”结果）、检测机构名称和联系方式。<br />
          三、使用来华航班起飞地官方语言或英语，由来华航班执飞航司检查。<br />
          四、为纸质报告，如检测机构出具电子报告请打印后携带。<br />
        <br />
        <br />
        <a href="https://reserva.be/hirahatacovidtest/reserve?mode=service_staff&search_evt_no=2ceJwzMTU0tzABAARUAT4"
          css={`border:3px solid #f7b13c; border-radius: 6px; background-color: #fffff0; padding: .5em; margin: .5em;`}>
          预约赴华核酸检测
        </a>

      </P>
    </SubLayout>
  );
};

export default Exams;
